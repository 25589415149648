import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

//-----------------Icons----------------------
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Calender } from "../assets/icons/calendar.svg";
import { ReactComponent as Compass } from "../assets/icons/compass.svg";
import { ReactComponent as Multiple } from "../assets/icons/multiple-documents.svg";
import { ReactComponent as Document } from "../assets/icons/document.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";

// -----------PDFViewer imports----------------
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { searchPlugin } from "@react-pdf-viewer/search";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "../styles/pdf-modal.css";

import { toast } from "react-toastify";
// import usePdfViewerModal from "../custom-hooks/usePdfViewerModal";

const PdfViewerModal = ({ show, handleClose, searchQuery, row }) => {
  //todo Using hook
  // const {
  //   defaultLayoutPluginInstance1,
  //   defaultLayoutPluginInstance2,
  //   pageNavigationPluginInstance,
  //   searchPluginInstance,
  //   maxPage,
  //   page,
  //   pdfUrl,
  //   enteredPageNumber,
  //   handlePageNumberChange,
  //   handleNextPage,
  //   handlePreviousPage,
  //   handleCopyButtonClick,
  //   handleEnterKey,
  //   isNextButtonDisabled,
  //   isPreviousButtonDisabled,
  //   coll_type,
  //   coll_name,
  //   title,
  //   fixedDate,
  //   date_of_publication,
  //   coll_location,
  //   coll_source,
  //   publisher,
  //   place_of_publication,
  // } = usePdfViewerModal({ show, handleClose, searchQuery, row });

  //For toolbar with print and download enabled
  const defaultLayoutPluginInstance2 = defaultLayoutPlugin();

  //For toolbar with print and download disabled
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const transform = (slot) => ({
    ...slot,
    // Disabling print, download, and open file buttons
    Download: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
  });
  const defaultLayoutPluginInstance1 = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => {
      return <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    },
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  var searchPluginInstance = searchPlugin();
  if (searchQuery !== "") {
    searchPluginInstance = searchPlugin({
      keyword: searchQuery?.split(" "),
      highlightAll: true,
      onHighlightKeyword: (props) => {
        props.highlightEle.style.backgroundColor = "rgba(255, 255, 0, .5)";
        // props.highlightEle.style.border = "1px solid rgba(255, 165, 0, 0.8)";
        //! Uncomment for orange shadow on highlight
        // props.highlightEle.style.boxShadow = "0 0 5px rgba(255, 0, 0, 0.6)";
      },
    });
  }

  const [maxPage, setMaxPage] = useState(row?.number_of_pages || 0);
  const [page, setPage] = useState(row?.page_number || 0);
  const [pdfUrl, setPDFurl] = useState(row?.AWS_link || "");

  //Page number feature
  const [enteredPageNumber, setEnteredPageNumber] = useState("");

  const convertArabicToNumber = (arabicNumeral) => {
    const arabicToNumberMapping = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };
    let number = "";
    for (let i = 0; i < arabicNumeral.length; i++) {
      number += arabicToNumberMapping[arabicNumeral[i]] || arabicNumeral[i];
    }
    return parseInt(number);
  };

  const handlePageNumberChange = (e) => {
    const inputValue = e.target.value;
    setEnteredPageNumber(inputValue);
  };

  const navigateToEnteredPage = () => {
    const pageNumber = convertArabicToNumber(enteredPageNumber);
    if (pageNumber >= 1 && pageNumber <= maxPage) {
      setPage((prevPage) => {
        setPDFurl(get_new_file(pageNumber));
        return pageNumber;
      });
    }
  };

  const viewerPluginInstances = row?.is_download_blocked
    ? [
        defaultLayoutPluginInstance1,
        pageNavigationPluginInstance,
        searchPluginInstance,
      ]
    : [
        defaultLayoutPluginInstance2,
        pageNavigationPluginInstance,
        searchPluginInstance,
      ];

  useEffect(() => {
    if (pdfUrl) {
      viewerPluginInstances.forEach((plugin) => {
        if (plugin && typeof plugin.setCurrentPage === "function") {
          plugin.setCurrentPage(enteredPageNumber);
        }
      });
    }
  }, [pdfUrl, enteredPageNumber]);

  useEffect(() => {
    if (row) {
      setMaxPage(row.number_of_pages);
      setPage(row.page_number || 1);
      setPDFurl(row.AWS_link);
    }
  }, [row]);

  const {
    coll_type,
    coll_name,
    title,
    date,
    date_of_journal,
    date_of_publication,
    coll_location,
    place_of_publication,
    coll_source,
    publisher,
    issue_number,
    volume,
    AWS_link,
    authors,
  } = row || {};

  const fixedDate = date || date_of_journal;

  // Copy citation

  const [copySuccess, setCopySuccess] = useState("");

  let citationText;

  if (coll_type === "periodical") {
    citationText = `${coll_name}, Volume ${volume}, Issue ${issue_number}, ${
      coll_type === "periodical" ? fixedDate : date_of_publication
    }, Khayrallah Center for Lebanese Diaspora Studies arabicsearch.org, ${coll_source}, ${AWS_link}`;
  } else {
    // First name Last name, Title, (Place of Publication: Publisher, Year), Khayrallah Center for Lebanese Diaspora Studies arabicsearch.org.
    citationText = `${authors}, ${title}, (${place_of_publication}: ${publisher}, ${date_of_publication}), Khayrallah Center for Lebanese Diaspora Studies arabicsearch.org, ${AWS_link}`;
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(citationText);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (err) {
      setCopySuccess("Failed to copy");
    }
  };

  const get_new_file = (currentPage) => {
    const prefix = pdfUrl.split("_").slice(0, -1).join("_");
    return `${prefix}_${currentPage}.pdf`;
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => {
        const newPage = prevPage - 1;
        setPDFurl(get_new_file(newPage));
        return newPage;
      });
    }
  };

  const handleNextPage = () => {
    if (page < maxPage) {
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        setPDFurl(get_new_file(newPage));
        return newPage;
      });
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      navigateToEnteredPage();
    }
  };

  //Todo Zoom debounce

  const isPreviousButtonDisabled = page === 1;
  const isNextButtonDisabled = page === maxPage;

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(pdfUrl);
    toast.success("URL copied to clipboard!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    });
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="my-modal">
      <Modal.Body closeButton style={{ justifyContent: "space-between" }}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-auto ColStyle">
              <div className="CollNameStyle">
                {coll_type === "periodical" ? coll_name : title}
              </div>
            </div>
            <div className="col-md-auto ColStyle">
              <Calender className="IconStyle" />
              <div className="DataStyle">
                {coll_type === "periodical" ? fixedDate : date_of_publication}
              </div>
            </div>
            <div className="col-md-auto ColStyle">
              <Compass className="IconStyle" />
              <div className="DataStyle">
                {coll_type === "periodical"
                  ? coll_location
                  : place_of_publication}
              </div>
            </div>
            <div className="col-md-auto ColStyle">
              <Multiple className="IconStyle" />
              <div className="DataStyle">
                {coll_type === "periodical" ? coll_source : publisher}
              </div>
            </div>

            <div
              className="col-sm-auto ColStyle"
              style={{ justifyContent: "end" }}
            >
              <Button
                disabled={isNextButtonDisabled}
                onClick={handleNextPage}
                className="ButtonStyle"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span style={{ paddingLeft: "7px" }}>Next</span>
              </Button>
            </div>
            <div
              className="col-sm-auto ColStyle"
              style={{ justifyContent: "center" }}
            >
              <Document className="IconStyle" />
              <div className="DataStyle">
                Page {page} of {maxPage}
              </div>
            </div>
            <div
              className="col-sm-auto ColStyle"
              style={{ justifyContent: "start" }}
            >
              <Button
                disabled={isPreviousButtonDisabled}
                onClick={handlePreviousPage}
                className="ButtonStyle"
              >
                <span style={{ paddingRight: "7px" }}>Previous</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
            <div
              className="col-sm-auto ColStyle"
              style={{ justifyContent: "end" }}
            >
              <Button
                onClick={handleCopyButtonClick}
                className="CopyButtonStyle"
              >
                <span style={{ paddingRight: "5px" }}> Copy URL</span>
              </Button>
            </div>
            <div className="col-md-auto ColStyle">
              <label style={{ marginRight: "0.6rem" }}>Go to page</label>
              <input
                type="text"
                value={enteredPageNumber}
                onChange={handlePageNumberChange}
                onKeyDown={handleEnterKey}
                className="PageNumberInput"
              />
            </div>
            <div className="col ColStyle" style={{ justifyContent: "end" }}>
              <Close onClick={handleClose} className="CloseBtnStyle" />
            </div>
          </div>
          <div className="row">
            <strong>Citation:</strong>
            <span>
              {citationText}
              <Button
                style={{ marginLeft: "0.5rem" }}
                onClick={copyToClipboard}
                className="CopyButtonStyle"
              >
                <span style={{ paddingRight: "5px" }}> Copy Citation</span>
              </Button>
              {copySuccess && (
                <span style={{ color: "green", marginLeft: "10px" }}>
                  {copySuccess}
                </span>
              )}
            </span>
          </div>
        </div>

        {pdfUrl && (
          <div className="container mt-3">
            <Worker workerUrl={process.env.REACT_APP_PDF_WORKER}>
              <div>
                <Viewer
                  key={pdfUrl}
                  theme="dark"
                  fileUrl={pdfUrl}
                  plugins={
                    row?.is_download_blocked
                      ? [
                          defaultLayoutPluginInstance1,
                          pageNavigationPluginInstance,
                          searchPluginInstance,
                        ]
                      : [
                          defaultLayoutPluginInstance2,
                          pageNavigationPluginInstance,
                          searchPluginInstance,
                        ]
                  }
                />
              </div>
            </Worker>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PdfViewerModal;
